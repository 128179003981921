
.raiseInfo{

	padding:10px;	
	
	h2{
		margin-bottom : 1em;	
	}

	.explications {
		
		font-size : 14px;
	
	}
}

#raiseChooseClientContainer .choicesLst {
	margin-top:1em;
	border : 1px solid #ccc;	
	border-collapse: collapse;
	td, th {
		
		border-left : 1px solid #ccc;	
		border-right : 1px solid #ccc;
	}
	
	tr {
		border-bottom : 1px solid #999;	
	} 
	td, th {
		padding:5px;	
	}
	th {
		font-weight : bold;
		background : #ddd;	
	}
	th.actions{
		width:150px;	
	}
}