
.reminder-quick-info {
	
	a, a:visited {	
		color:black;
		padding-left: 5px;
	}
	
		
	
}