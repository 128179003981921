.ficheClient {
	
	box-sizing:border-box;
	padding: 0 10px 0 10px;
	width : 1200px;
	
	.head-container .raisonSociale, .head-container .fullName {
		font-size:1.1em;
		font-weight:bold;	
		color:#444;
	}
	.head-container .idClient {
		font-size:1.4em;
		line-height: 30px;
			
	}
	.head-container .enseigne {
		font-size:1.4em;
		line-height: 30px;
		font-weight:bold;	
	}

	span.label, div.label {
		font-weight : bold;
	}	

	h3 {
		display:inline-block;
		padding-top: 0.5em;
		margin-left:10px;
		margin-bottom:10px;
		
	}
	.info-content {
		width:100%;
	
	}
	
	.notes {
		box-sizing:border-box;
		display:block;
		width:100%;
	}
	.notes .formComments {
		box-sizing:border-box;
		width:100%;
		margin-top:20px;
		padding:10px;
		display : flex;
		flex-wrap : wrap;
		
		label {
		
			font-weight:bold;
			vertical-align:top;
			
		}
	
		textarea{
			display:block;
			width:670px;
			height:5.5em;
			margin: -5px 10px 0 10px;
			border : 1px solid #ccc;
			background:white;
		}
	
		input.saveComments {
			font-size : 'Arial Normal', Arial, Helvetica, sans-serif;
			height : 30px;
			width:100px;	
		}
		.saveComments_submitAndMsgs{
			flex-grow: 1;
		}
		.saveCommentsMsgOK,.saveCommentsMsgFail {
		
			text-align:center;
			
			
		}
		.lastCommentInfo {
			margin-top:1em;
			text-align : right;	
			width: 100%;
			color: #888;
		} 
	}

	.coords {
		padding : 0 10px 0 10px;
		width:100%;
		display:flex;
		flex-wrap : wrap;
		
	}
	.recurInfo {
		width:100%;
		display:flex;
		flex-wrap : wrap;
		li.lastCallDate {
			display:block;
			width:50%;	
		}
		li.nextCallDate {
			display:block;
			width:50%;	
		}
		li.extraCallDate,li.recurPauseDates {
			display:block;
			width:100%;	
		}
		
	}
	
	
	.halfCol {
			display:block;
			width:50%;
			padding:0;
	}
	
	.section2 {
		box-sizing:border-box;
		padding:0;
		width:100%;
		display:flex;
		flex-wrap : wrap;	
		
		.infosClient {
		
			display:block;
			width:50%;	
			height:452px;
			overflow-y: scroll;
			border : 1px solid #ccc;
			background:#ddd;
			box-sizing:border-box;
		}
		.joursLivraison {
			padding:3px;
			border: 1px solid #ddd;
			margin-top:3px;	
			padding-top:5px;
			font-size : 0.9em;	
		}
	
		.joursLivraison span{
			margin-right:3px;	
			vertical-align:top;
		}
	
		.joursLivraison input[type=checkbox]{
				
		}
	
		.infosClient-bloc {
			margin:10px;
			padding:10px;
			border : 1px solid #ccc;
			background:white;
			
			h3{
				margin-bottom:1em;
				
			}
		}
		hr {
			margin-top:0;	
		}
		.bloc-row {
			margin-bottom:0.5em;
			margin-left:10px;
		}
	}

	.editTicket fieldset{
	
		border : 1px solid #ccc;	
		
	}

	.editTicket legend{
	
		margin: 0 10px 5px 10px;
		padding : 0 2px; 	
		
	}

	.smsPopIn {
	
		box-sizing:border-box;
		padding:0;
		width:100%;
		border : 1px solid #ccc;
		margin-bottom:1em;
		
		.smsPopIn_actions{
			
			text-align:right;
		}
		
		#smsContent{
			
			margin:0 1em 0 1em;
			width:750px;
			height:5em;
		}
	
		#smsPopIn_sendBtn{
			
			margin:1em 1em 1em 0.5em;

		}
		#smsPopIn_closeBtn{
			
			margin:1em 1em 1em 0;

		}		
	}
}
