
.infoContent_impexp{
	
	padding : 10px;	
	
}

.impexModulesList_item{
	
	display:inline-block;
	width:300px;
	border : 1px solid #ddd;
	padding:10px;	
	margin-right:10px;
	vertical-align :top;
	
	header {
		text-transform:uppercase;
		font-weight:bold;	
		
		
	}
}
.infos .impexModulesList_item ul {
    float: none;
}